import contact from './lib/contact'
import rebox from './lib/rebox'
import menuDescription from './lib/menu-description'
import { toggleBelowFold } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  menuDescription({
    items: '.second-level-sub-navigation li > a[data-description]',
    currentItem: '.second-level-sub-navigation li.current > a[data-description]',
    description: '.second-level-sub-navigation .content'
  })

  $('.callout.backend .carousel').carousel({
    interval: false
  })

  // close burger menu if cklcik on id to show target
  $('.header-nav-mobile-list li a').click(function () {
    $('.header-nav-content').collapse('hide')
  })

  $('.navbar-toggler').click(function () {
    $('.header-nav').toggleClass('active')
  })

  $(document).scroll(function () {
    const scroll = $(this).scrollTop()

    if (scroll > 0) {
      $('.callout .header-nav').addClass('fixed')
    } else {
      $('.callout .header-nav').removeClass('fixed')
    };

    if (scroll > 400) {
      $('.callout.home .header-nav').addClass('fixed')
      $('.header.home .header-logo').addClass('resize')
      $('.header.home').addClass('resize')
    } else {
      $('.callout.home .header-nav').removeClass('fixed')
      $('.header.home .header-logo').removeClass('resize')
      $('.header.home').removeClass('resize')
    }
  })
})

const triggerTabList = [].slice.call(document.querySelectorAll('#item-tab li:first-child .nav-link'))
triggerTabList.forEach(function (triggerEl) {
  const tabTrigger = new bootstrap.Tab(triggerEl)

  triggerEl.addEventListener('click', function (event) {
    event.preventDefault()
    tabTrigger.show()
  })
})

$('.tab-content .tab-pane:first').addClass('show active')
$('#item-tab li .nav-link:first').addClass('active')

$(document).ready(function () {
  const selector = $('.third-level-sub-navigation li')
  $(selector).on('click', function () {
    $(selector).removeClass('current')
    $(this).addClass('current')
  })

  $('.third-level-sub-navigation li a').click(function () {
    $('.header-nav-content').collapse('hide')
  })

  $('.language-selected span').click(function () {
    $('.languages-list').slideToggle()
    $('.languages').toggleClass('active')
  })
})

$(window).on('resize', function () {
  if ($(window).height() <= 1024) {
    $('.highlight.highlightWoBg').addClass('whiteBg')
  } else {
    $('.highlight.highlightWoBg').removeClass('whiteBg')
  };
})

$('.shopping-widget').on('mouseenter', widgetMouseIn).on('mouseleave', widgetMouseOut)

const widgetAnimationElement = $('.shopping-widget  .shopping-widget-photo  .animated-photo')

function widgetMouseIn () {
  widgetAnimationElement.removeClass('animation-in')
  widgetAnimationElement.addClass('animation-out')
}

function widgetMouseOut () {
  widgetAnimationElement.removeClass('animation-out')
  widgetAnimationElement.addClass('animation-in')
}

$(window).on('load resize', function () {
  function distanceBetweenElements (elementOne, elementTwo) {
    let distance = -1

    const x1 = elementOne.offset().top
    const y1 = elementOne.offset().left
    const x2 = elementTwo.offset().top
    const y2 = elementTwo.offset().left
    const xDistance = x1 - x2
    const yDistance = y1 - y2

    distance = Math.sqrt(
      (xDistance * xDistance) + (yDistance * yDistance)
    )

    return distance + 'px'
  }

  const firstItem = $('.process-collection--wrapper .neos-contentcollection .process-item:first .after')
  const lastItem = $('.process-collection--wrapper .neos-contentcollection .process-item:last .after')

  const spaceBetweenProcessDots = distanceBetweenElements(firstItem, lastItem)

  $('.process-collection--wrapper .neos-contentcollection .process-item:first .line').css('--widthLine', (spaceBetweenProcessDots))
})

document.addEventListener('DOMContentLoaded', function () {
  const elem = document.getElementById('svg-object')
  if (elem) {
    if ($(window).width() <= 1024 && elem) {
      const panzoom = Panzoom(elem, {
        maxScale: 10,
        step: 1.5
      })

      panzoom.pan(100, 100)
      panzoom.zoom(1, { animate: true })
      elem.parentElement.addEventListener('wheel', panzoom.zoomWithWheel)

      // eslint-disable-next-line no-undef
      resetButton.addEventListener('click', () => {
        panzoom.pan(10, 10)
        panzoom.zoom(1)
      })
    } else {
      // eslint-disable-next-line no-undef
      const panzoom = Panzoom(elem, {
        maxScale: 10,
        step: 0.3
      })

      panzoom.pan(100, 100)
      panzoom.zoom(1, { animate: true })
      elem.parentElement.addEventListener('wheel', panzoom.zoomWithWheel)

      // eslint-disable-next-line no-undef
      resetButton.addEventListener('click', () => {
        panzoom.pan(1, 1)
        panzoom.zoom(1)
      })

      $(function () {
        $('.step-svg a[data-toggle="tooltip"]').tooltip()
      })
    }
  }
})

const processItems = document.querySelectorAll('.process-item')
processItems.forEach((item, index) => {
  const numberElement = document.createElement('span')
  numberElement.className = 'process-number'
  numberElement.textContent = `${index + 1}`
  const afterElement = item.querySelector('.after')
  afterElement.appendChild(numberElement)
})

window.addEventListener(
  'scroll',
  () => {
    document.body.style.setProperty(
      '--scroll',
      window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
    );
  },
  false
);

$(document).ready(function () {
  const allContainer = document.querySelectorAll('.grid-content')
  window.onscroll = function () {
    allContainer.forEach(section => {
      if (section.getBoundingClientRect().top <= 500) {
        section.classList.add('active')
      }
    })
  }
})
